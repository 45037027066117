<template>
  <div class="z-upload-image">
    <div class="from">
      <el-input class="z-input" :disabled="disabledInput" clearable v-model="value"></el-input>
      <el-upload :accept="accept" action="" :disabled="disabled" :show-file-list="false" ref="refUpload" :http-request="uploadRequest" :on-success="uploadSuccess">
        <span style="display: inline-flex">
          <slot name="default" :loading="loading">
            <el-button :loading="loading" icon="Plus">{{ btnName }}</el-button>
          </slot>
        </span>
      </el-upload>
    </div>
  </div>
</template>

<script>
import {uploadImg} from "@/api/request";

export const platformsettingsUploadPlatformFile= params => uploadImg({url: `app-manage/version/upload/`,params})

export default {
  name: "baseUploadImage",
  data() {
    return {
      loading: false,
      showImage: false,
    }
  },
  props: {
    btnName: {
      type: String,
      default: '选择文件'
    },
    accept: {
      // 类型说明
      // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#unique_file_type_specifiers
      type: String,
      default: "*/*"
    },
    modelValue: {
      type: String,
      default: ""
    },
    disabledInput: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    value: {
      set(v) {
        this.$emit('update:modelValue', v); // visible改变时同步父组件modelValue的值
      },
      get() {
        return this.modelValue
      }
    }
  },
  methods: {
    async uploadRequest(param) {
      this.loading = true
      let obj = await platformsettingsUploadPlatformFile(param)
      if (obj.code === 2000) {
        let res = ''
        if (obj.data.data[0].indexOf("://") >= 0) {
          res = obj.data.data[0]

        } else {
          res = url.split('/api')[0] + obj.data.data[0]
        }
        this.value = res
      } else {
        this.$message.warning(res.msg)
      }
      this.loading = false
    },
    uploadSuccess() {
      this.$refs.refUpload.clearFiles()
    }
  },
}
</script>

<style lang="scss" scoped>
.z-upload-image {

  .from {
    display: flex;
    justify-content: center;
    align-items: center;

    .z-input {
      flex: 1 1 auto;
      margin-right: 10px;
    }
  }

  .view-img {
    margin-top: 5px;
    border: 1px solid #ddd;
    padding: 5px;
    height: 150px;
    width: auto;
    object-fit: cover;
  }
}

</style>