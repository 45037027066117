<template>
  <div>
    <ly-crud ref="lycrud" v-bind="crudConfig"></ly-crud>
  </div>
</template>

<script lang="jsx">
import LyCrud from "@/components/lycrud";
import templateData from "@/components/dict/crudTemplateData"
import {h, resolveComponent} from 'vue';

import {ajaxGet, ajaxPost, ajaxDelete, ajaxPut} from '@/api/request';

const appAdd = params => ajaxPost({url: `app-manage/app/`, params})
const appDelete = params => ajaxDelete({url: `app-manage/app/`, params})
const appEdit = params => ajaxPut({url: `app-manage/app/`, params})
const appSearch = params => ajaxGet({url: `app-manage/app/`, params})


export default {
  name: "appManageApp",
  components: {LyCrud},
  data() {
    return {
      //crud配置
      crudConfig: {
        //crud请求方法配置
        crudRequest: {
          add: appAdd,
          del: appDelete,
          edit: appEdit,
          search: appSearch,
        },
        //搜索栏目配置
        searchBar: {
          showSearchBar: true,//显示搜索栏目
          searchColums: [
            {label: '应用名称', type: 'input', prop: 'search', width: 200, maxlength: 64, placeholder: '请输入应用名称'},
            {
              label: '状态', type: 'select', prop: 'status', width: 100, placeholder: '请选择',
              options: [
                {value: 1, label: '正常'},
                {value: 2, label: '禁用'}
              ]
            },
          ]
        },
        //显示分页
        showPagination: true,
        //分页配置
        pageparams: {
          limit: 10,//每页显示的条数(默认每页显示10条)//非必填
          //pageSizes:[10,20,30,40,50,200],//非必填
          // layout:'total, sizes, prev, pager, next, jumper',//非必填
        },
        //crud按钮配置
        rowHandle: {
          width: 180,//操作列宽度,0表示不显示表格操作列
          fixed: "right",//固定操作列在右侧
          permission: {//增删改查按钮权限控制（是否显示）
            add: this.hasPermission(this.$options.name, 'Create'),//bool型 this.$options.name获取当前组件的name本例为userManageCrud
            del: this.hasPermission(this.$options.name, 'Delete'),
            edit: this.hasPermission(this.$options.name, 'Update'),
            search: this.hasPermission(this.$options.name, 'Search'),
            detail: this.hasPermission(this.$options.name, 'Retrieve'),
          }
        },
        //crud弹窗属性
        formOptions: {
          width: '45%',//dialog弹窗宽度：类型：百分比或字符串
          gutter: 20, // Layout布局栅格间隔
        },
        //crud表格属性
        tableOptions: {
          border: true,
          showHeader: true,
        },
        showSelectable: true,//表格显示复选项框
        //table表头列
        tableColumns: [
          {
            label: 'ID', prop: 'id', type: 'input', width: '300', sortable: false,
            form: {span: 24, hidden: true,/* 编辑时隐藏，添加时去除 */}
          },
          {
            label: '应用名称', prop: 'name', type: 'input', width: '160', sortable: false, hidden: false,
            form: {
              //表单属性
              span: 24,
              rules: [{required: true, message: '应用名称必填项'}],
              placeholder: '请输入应用名称',
            }
          },
          {
            label: '应用描述', prop: 'describe', type: 'textarea', minWidth: 140, sortable: false, hidden: false,
            form: {
              //表单属性
              span: 24,
            }
          },
          {
            label: '状态', prop: 'status', type: 'radio', width: 80, sortable: false, hidden: false,
            render: (row) => {
              if (row.status === 2) {
                return <el-tag type="warning" disable-transitions>停用</el-tag>
              } else {
                return <el-tag type="success" disable-transitions>启用</el-tag>
              }
            },
            form: {
              //表单属性
              span: 24,
              options: [
                {value: 1, label: '启用'},
                {value: 2, label: '停用'},
              ],//radio选项属性值
              defaultValue: 1,//新增时的默认值
              // valueChange(e){//值变化回调事件
              //     console.log(e,'---变化的值')
              // },
            }
          },
          {label: '创建时间', prop: 'create_datetime', width: '180', sortable: false, hidden: false},
        ],
      },

    }
  },
  methods: {
    setFull() {
      this.$refs.lycrud.setFull()
    }
  },
}
</script>

<style scoped>

</style>